<template>
  <div class="log-section">
    <div v-if="logs && logs.length > 0" style="width: 80%;">
      <div class="device-info">
        <h3>{{ $t('MaintenanceDeviceLogs.device_active_time') }}: {{ formatMinutes(logs[0]?.device_minutes_active ?? 0) }}</h3>
        <h3>{{ $t('MaintenanceDeviceLogs.last_received_data') }}: {{ formatMinutes(getTimeSinceLastData(logs[0]?.device_date_time ?? 0)) }}</h3>
      </div>
      <div>
        <!-- Logs Table -->
        <table v-if="logs.length > 0" class="log-table">
          <thead>
            <tr>
              <th>{{ $t('MaintenanceDeviceLogs.fw_main_controller') }}</th>
              <th>{{ $t('MaintenanceDeviceLogs.fw_weight_electronics') }}</th>
              <th>{{ $t('MaintenanceDeviceLogs.firmware_version') }}</th>
              <th>{{ $t('MaintenanceDeviceLogs.reference_height') }}</th>
              <th>{{ $t('MaintenanceDeviceLogs.system_units') }}</th>
              <th>{{ $t('MaintenanceDeviceLogs.date') }}</th>
              <th>{{ $t('MaintenanceDeviceLogs.time') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in logs" :key="log.device_time">
              <td>{{ log.device_fw_main_controller }}</td>
              <td>{{ log.device_fw_weight_electronics }}</td>
              <td>{{ log.device_sw_version }}</td>
              <td>{{ log.device_ref_height }}</td>
              <td>{{ log.device_system_units === 0 ? 'METRIC' : log.device_system_units === 1 ? 'IMPERIAL' : 'UNKNOWN' }}</td>
              <td>{{ formatLocalDate(log.device_date_time) }}</td>
              <td>{{ formatLocalTime(log.device_date_time) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="logs && logs.length > 0">
        <button @click="buttonClickShowMoreData" class="back-button">{{ $t('MaintenanceDeviceLogs.show_more') }}</button>
      </div>
    </div>
    <p v-else>{{ $t('MaintenanceDeviceLogs.no_logs_found') }}</p>
  </div>
</template>
<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      multiplicator: 1,
      intervalId:null,
      logs: [] // Store logs fetched from API
    };
  },
  async mounted() {
    await this.fetchLogsForDevice();
    this.intervalId = setInterval(this.fetchLogsForDevice, 30000);
  },
  beforeUnmount(){
    clearInterval(this.intervalId);
  },
  watch: {
    multiplicator(newValue) {
      console.log(`Multiplicator changed to ${newValue}`);
      this.fetchLogsForDevice(); // Automatically re-fetch when multiplicator changes
    }
  },
  methods: {
    /// Emit events that trigger top level functions
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    async buttonClickShowMoreData() {
      this.multiplicator++;
    },
    
    /**
     * Fetches logs for a specified device from the backend API.
     *
     * @returns {Promise<void>} A promise that resolves when the logs are successfully fetched and stored.
     */
    async fetchLogsForDevice() {
      try {
        // Update Keycloak token
        await keycloak.updateToken(30);
        const token = keycloak.token;

        // Check if the token is available
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        // Make an API call to fetch logs
        const response = await axios.get(
          `${backendUrl}/api/maintenance/logs`,
          {
            params: { id: this.deviceId, multiplicator: this.multiplicator }, // Pass deviceId as a query parameter
            headers: { Authorization: `Bearer ${token}` } // Include token in the headers
          }
        );

        // Check if the request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }

        // Validate response data
        if (!response.data || !response.data.data) {
          this.showError(this.$t('general_errors.response_invalid_structure'));
          return;
        }

        if (!response.data.success) {
          this.showError(this.$t('general_errors.response_with_error'));
          return;
        }

        // Store the fetched logs in the component's state
        this.logs = response.data.data; // Assuming logs is a data property in your component
      } catch (error) {
        console.error(`fetchLogsForDevice: ${error}`); // Log the error for debugging
        this.showError(this.$t('general_errors.response_internal_error'));
      }
    },
    formatLocalDate(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleDateString("en-GB"); // "en-GB" ensures dd.mm.yyyy format
    },
    formatLocalTime(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Ensures 24-hour format
      });
    },
    formatMinutes(activeTimeInMinutes) {
      const minutes = activeTimeInMinutes || 0;
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${String(hours).padStart(2, '0')}h ${String(mins).padStart(2, '0')}m`;
    },
    getTimeSinceLastData(deviceDateTime) {
        if (!deviceDateTime) return 0;

        const lastTimestamp = new Date(deviceDateTime).getTime(); // Convert to milliseconds
        const now = Date.now(); // Get current timestamp in milliseconds

        const differenceInMinutes = Math.floor((now - lastTimestamp) / (1000 * 60)); // Convert ms → minutes
        return Math.max(differenceInMinutes, 0); // Ensure it never returns negative
    }
  }
};
</script>

<style scoped>
.log-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.log-table th,
.log-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.log-table th {
  background-color: #3498db;
  color: white;
}

.log-table tr:hover {
  background-color: #f1f1f1;
}

.log-table td {
  color: #333;
}

.log-section p {
  color: #7f8c8d;
  font-style: italic;
}
.back-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #179b0b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Spacing for the icon */
.back-button i {
  margin-right: 8px;
}

/* Hover effect */
.back-button:hover {
  background-color: #1f7917;
}

/* Focus effect */
.back-button:focus {
  outline: none;
}

/* Active effect */
.back-button:active {
  background-color: #1e8015;
}
.device-info {
    display: flex;        /* Enables side-by-side alignment */
    gap: 20px;            /* Adds space between items */
    align-items: center;  /* Ensures vertical alignment */
}
</style>