<template>
  <div v-if="isInMaintenanceMode">
    <div class="chart-section">
      <LineChartPositions :dataSets="transformedDataPointsHeightCurrentLow" />
      <LineChartMotorCurrent :dataPoints="transformedDataPointsMotorCurrent" />
      <LineChartWeight :dataPoints="transformedDataPointsWeight" />
    </div>

    <div class="log-section">
      <!-- Repair data Logs Table -->
      <table class="log-table">
        <thead>
          <tr>
            <th>{{ $t('MaintenanceDeviceRepairData.time') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.support_status') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.motor_current') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.motor_current_max') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.upper_position') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.current_position') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.lower_position') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.motor_stop_code_1') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.motor_stop_code_2') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.weight') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.user_panel_mode') }}</th>
            <th>{{ $t('MaintenanceDeviceRepairData.autodrive_testing_mode') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="repairDataLog in repairDataLogs" :key="repairDataLog.device_date_time">
            <td>{{ formatLocalTime(repairDataLog.device_date_time) }}</td>
            <td>{{ repairDataLog.device_data_suppportStatus }}</td>
            <td>{{ repairDataLog.device_data_motorCurrent }}</td>
            <td>{{ repairDataLog.device_data_motorCurrentMax }}</td>
            <td>{{ repairDataLog.device_data_upperPosition }}</td>
            <td>{{ repairDataLog.device_data_currentPosition }}</td>
            <td>{{ repairDataLog.device_data_lowerPosition }}</td>
            <td>{{ repairDataLog.device_data_motorStopCode1 }}</td>
            <td>{{ repairDataLog.device_data_motorStopCode2 }}</td>
            <td>{{ repairDataLog.device_data_weight }}</td>
            <td>{{ repairDataLog.device_data_userPanelMode }}</td>
            <td>{{ repairDataLog.device_data_autodriveTestingMode }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p v-else>{{ $t('MaintenanceDeviceRepairData.device_not_in_maintenance_mode') }}</p>
</template>
<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';

import { defineAsyncComponent } from 'vue';
const LineChartWeight = defineAsyncComponent(() =>
  import('./Charts/LineChartWeight.vue')
);
const LineChartMotorCurrent = defineAsyncComponent(() =>
  import('./Charts/LineChartMotorCurrent.vue')
);
const LineChartPositions = defineAsyncComponent(() =>
  import('./Charts/LineChartPositions.vue')
);

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    },
    isInMaintenanceMode: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isInMaintenanceMode: {
      immediate: true,
      handler(newStatus) {
        if (newStatus) {
          this.$emit('showSuccess', this.$t('MaintenanceDeviceRepairData.maintenance_mode_enabled'));
        } else {
          this.$emit('showError', this.$t('MaintenanceDeviceRepairData.maintenance_mode_disabled'));
        }
      }
    }
  },
  components: {
    LineChartWeight,
    LineChartMotorCurrent,
    LineChartPositions
  },
  computed: {
    transformedDataPointsWeight() {
      return this.repairDataLogs
        .map(log => log.device_data_weight ?? null);
    },
    transformedDataPointsMotorCurrent() {
      return this.repairDataLogs
        .map(log => log.device_data_motorCurrent ?? null);
    },
    transformedDataPointsHeightCurrentLow() {
      // Create `dataSets` object with `upperPositions` and `lowerPositions` arrays
      const upperPositions = [];
      const currentPositions = [];
      const lowerPositions = [];

      // Iterate over repairDataLogs once and ensure all three positions are present
      for (const log of this.repairDataLogs) {
        upperPositions.push(log.device_data_upperPosition ?? null);
        currentPositions.push(log.device_data_currentPosition ?? null);
        lowerPositions.push(log.device_data_lowerPosition ?? null);
      }

      return { upperPositions, currentPositions, lowerPositions };
    }
  },
  data() {
    return {
      intervalId: null,
      repairDataLogs: []
    };
  },
  async mounted() {
    await this.fetchRepairDataLogsForDevice();
    this.intervalId = setInterval(this.fetchRepairDataLogsForDevice, 200);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    /// Emit events that trigger top level functions
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    /**
     * Fetches repair logs for a specified device from the backend API.
     *
     * @returns {Promise<void>} A promise that resolves when the logs are successfully fetched and stored.
     */
    async fetchRepairDataLogsForDevice() {
      try {
        if(!this.isInMaintenanceMode) return;

        // Update Keycloak token
        await keycloak.updateToken(30);
        const token = keycloak.token;

        // Check if the token is available
        if (!token) {
          this.showError(this.$t('MaintenanceDeviceRepairData.auth_token_missing'));
          return;
        }

        const response = await axios.get(
          `${backendUrl}/api/maintenance/repairdatalogs`,
          {
            params: { id: this.deviceId },
            headers: { Authorization: `Bearer ${token}` }
          });

        // Check if the request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }

        // Validate response data
        if (!response.data || !response.data.data) {
          this.showError(this.$t('MaintenanceDeviceRepairData.invalid_response_structure'));
          return;
        }

        if (!response.data.success) {
          this.showError(this.$t('MaintenanceDeviceRepairData.response_with_error')`: ${response.data.error}`);
          return;
        }

        // Store the fetched logs in the component's state
        if (this.isInMaintenanceMode) //if user closed stream while request was send to server
          this.repairDataLogs = response.data.data; // Assuming logs is a data property in your component

      } catch (error) {
        console.error(`fetchRepairDataLogsForDevice: ${error}`); // Log the error for debugging
        this.showError(this.$t('MaintenanceDeviceRepairData.internal_error_fetching'));
      }
    },
    formatLocalDate(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleDateString();
    },
    formatLocalTime(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleTimeString();
    }
  }
}
</script>

<style scoped>
.log-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.log-table th,
.log-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.log-table th {
  background-color: #FFA500;
  color: white;
}

.log-table tr:hover {
  background-color: #f1f1f1;
}

.log-table td {
  color: #333;
}

.log-section p {
  color: #7f8c8d;
  font-style: italic;
}

.chart-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  width: 100vw; /* Ensures full viewport width */
  max-width: 100%; /* Prevents any additional width constraints */
  margin: auto; /* Centers content */
  box-sizing: border-box; /* Prevents padding from affecting width */
}


/* Apply styles to each child chart container */
.chart-section > * {
  flex: 1 1 50%; /* Each chart takes up to 50% of the container width */
  max-width: 50%; /* Limit to 50% width */
  box-sizing: border-box; /* Ensures padding/margin doesn't increase width */
}

@media (max-width: 800px) {
  .chart-section {
    flex-direction: column; /* Stack vertically on smaller screens */
  }
}
</style>