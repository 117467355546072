<template>
    <div v-if="keycloak.authenticated">
        <div>
            <section aria-labelledby="device-form-heading" class="device-form-section">
                <div class="form-container">
                    <!-- Input field and refresh button wrapper -->
                    <div class="input-refresh-wrapper">
                        <input v-model="filterString" class="modern-input" type="text"
                            :placeholder="$t('MaintenanceDeviceData.search_filter')" maxlength="50" />
                        <button @click="refreshList" class="refresh-btn">
                            <i class="fas fa-sync-alt"></i> <!-- Font Awesome icon -->
                        </button>
                    </div>

                    <!-- Pagination buttons -->
                    <div class="pagination-container">
                        <button class="pagination-button" @click="previousPageClick" :disabled="pageIndex === 0">
                            <i class="fas fa-angle-left"></i>
                        </button>
                        <button class="pagination-button" @click="nextPageClick"
                            :disabled="pageIndex === (lastIndexPage - 1)">
                            <i class="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </div>

        <div>
            <div v-if="sortedAndFilteredDeviceData.length === 0">
                {{ $t('MaintenanceDeviceData.no_devices_found') }}
            </div>

            <div class="table-wrapper" v-else>
                <table class="table" >
                    <thead>
                        <tr>
                            <th
                            class="status-column"
                            @click="sortBy('status')"
                            :class="{ sorted: sortKey === 'status' }"
                            style="cursor: pointer;"
                            >
                            {{ $t('MaintenanceDeviceData.status') }}
                            <span v-if="sortKey === 'status'">{{ sortAsc ? '▲' : '▼' }}</span>
                            </th>

                            <th
                            class="serial-number-column"
                            @click="sortBy('device_serial_number')"
                            :class="{ sorted: sortKey === 'device_serial_number' }"
                            style="cursor: pointer;"
                            >
                            {{ $t('MaintenanceDeviceData.serial_number') }}
                            <span v-if="sortKey === 'device_serial_number'">{{ sortAsc ? '▲' : '▼' }}</span>
                            </th>

                            <th
                            class="mac-address-column"
                            @click="sortBy('device_mac_address_hex')"
                            :class="{ sorted: sortKey === 'device_mac_address_hex' }"
                            style="cursor: pointer;"
                            >
                            {{ $t('MaintenanceDeviceData.mac_address') }}
                            <span v-if="sortKey === 'device_mac_address_hex'">{{ sortAsc ? '▲' : '▼' }}</span>
                            </th>

                            <th
                            class="firmware-version-column"
                            @click="sortBy('device_sw_version')"
                            :class="{ sorted: sortKey === 'device_sw_version' }"
                            style="cursor: pointer;"
                            >
                            {{ $t('MaintenanceDeviceData.firmware_version') }}
                            <span v-if="sortKey === 'device_sw_version'">{{ sortAsc ? '▲' : '▼' }}</span>
                            </th>

                            <th
                            class="first-reg-date-column"
                            @click="sortBy('first_registration_date')"
                            :class="{ sorted: sortKey === 'first_registration_date' }"
                            style="cursor: pointer;"
                            >
                            {{ $t('MaintenanceDeviceData.first_registration') }}
                            <span v-if="sortKey === 'first_registration_date'">{{ sortAsc ? '▲' : '▼' }}</span>
                            </th>

                            <th class="owner-data-column">
                            {{ $t('MaintenanceDeviceData.owner_data') }}
                            </th>

                            <th
                            class="latest-data"
                            @click="sortBy('latest_update')"
                            :class="{ sorted: sortKey === 'latest_update' }"
                            style="cursor: pointer;">
                            {{ $t('MaintenanceDeviceData.latest-time') }}
                            <span v-if="sortKey === 'latest_update'">{{ sortAsc ? '▲' : '▼' }}</span>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="device in sortedAndFilteredDeviceData" :key="device.device_id"
                            @click="viewDeviceDetails(device)" class="clickable-row">
                            <td class="status-column">
                                <StatusIcon :status="device.status" />
                            </td>
                            <td class="serial-number-column">{{ device.device_serial_number }}</td>
                            <td class="mac-address-column">{{ device.device_mac_address_hex }}</td>
                            <td class="firmware-version-column">{{ device.device_sw_version }}</td>
                            <td class="first-reg-date-column">{{ formatDate(device.first_registration_date) }}</td>
                            <td class="owner-data-column">{{ device.device_owner_desc }} {{ device.owner_surname }}</td>
                            <td class="latest-data-column">{{ formatDateTime(device.latest_update) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div v-else>
        <p>{{ $t('MaintenanceDeviceData.not_logged_in') }}</p>
    </div>
</template>

<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';
import StatusIcon from '@/components/StatusIcon.vue'

export default {
    components: {
        StatusIcon
    },
    async mounted() {
        this.resetPageIndex();
        this.fetchDevicesWithOwnersData();
    },
    data() {
        return {
            keycloak: keycloak,
            listOfDevices: [],   // Store all the devices
            filterString: '',
            ROWS_PER_PAGE: 10,
            pageIndex: 0,
            lastIndexPage: 0,

            
            // for sorting
            sortKey: '',
            sortAsc: true


        }
    },
    computed: {
        sortedAndFilteredDeviceData() {
            let devices = [...this.listOfDevices];

            // Step 1: Sort
            if (this.sortKey) {
                devices.sort((a, b) => {
                    let aVal = a[this.sortKey];
                    let bVal = b[this.sortKey];

                    if (this.sortKey === 'first_registration_date' || this.sortKey === 'latest_update') {
                        aVal = new Date(aVal);
                        bVal = new Date(bVal);
                    } else {
                        aVal = aVal?.toString().toLowerCase() || '';
                        bVal = bVal?.toString().toLowerCase() || '';
                    }

                    if (aVal === bVal) return 0;
                    return this.sortAsc ? (aVal > bVal ? 1 : -1) : (aVal < bVal ? 1 : -1);
                });
            }

            // Step 2: Filter
            if (this.filterString) {
                const searchLower = this.filterString.toLowerCase();

                devices = devices.filter(device => {
                    return (
                    device.device_id?.toLowerCase().includes(searchLower) ||
                    device.device_serial_number?.toLowerCase().includes(searchLower) ||
                    device.device_mac_address?.toLowerCase().includes(searchLower) ||
                    device.device_mac_address_hex?.toLowerCase().includes(searchLower) ||
                    device.device_description?.toLowerCase().includes(searchLower) ||
                    device.device_owner_desc?.toLowerCase().includes(searchLower) ||
                    device.owner_name?.toLowerCase().includes(searchLower) ||
                    device.owner_surname?.toLowerCase().includes(searchLower) ||
                    device.owner_email?.toLowerCase().includes(searchLower) ||
                    device.device_sw_version?.toLowerCase().includes(searchLower) ||
                    this.formatDate(device.first_registration_date).toLowerCase().includes(searchLower) ||
                    device.status?.toLowerCase().includes(searchLower)
                    );
                });
            }

            // Step 3: Paginate
            return this.paginatedDevices(devices);
        }

    },
    watch: {
        // Watch for changes in the filtered data to reset the page index
        filterString() {
            // Reset pageIndex whenever filtered data changes
            this.resetPageIndex();
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.resetPageIndex();
        },
        formatDate(dateString) {
            // Handle cases where the server returns "/"
            if (!dateString || dateString === "/") return "-";

            // Convert to a Date object
            const date = new Date(dateString);

            // Check if date is valid
            if (isNaN(date.getTime())) return "-";

            // Format as "day.month.year"
            return date.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            });
        },
        resetPageIndex() {
            this.pageIndex = 0;
        },
        paginatedDevices(deviceList) {
            // Calculate the number of pages based on the filtered device list
            this.lastIndexPage = Math.ceil(deviceList.length / this.ROWS_PER_PAGE);

            const start = this.pageIndex * this.ROWS_PER_PAGE;
            const end = start + this.ROWS_PER_PAGE;

            // Return only the devices for the current page
            return deviceList.slice(start, end);
        },
        previousPageClick() {
            if (this.pageIndex > 0) {
                this.pageIndex--;
            }
        },
        nextPageClick() {
            if (this.pageIndex < this.lastIndexPage - 1) {
                this.pageIndex++;
            }
        },
        viewDeviceDetails(device) {
            // Emit an event to notify parent (MainPage.vue) about the selected device
            this.$emit('device-selected', device);
        },
        /// Emit events that trigger top level functions
        showError(message) {
            this.$emit('showError', message);
        },
        showSuccess(message) {
            this.$emit('showSuccess', message);
        },
        async refreshList() {
            this.fetchDevicesWithOwnersData();
            this.resetPageIndex();
        },


        async fetchDevicesWithOwnersData() {
            try {
                await keycloak.updateToken(30);
                const token = keycloak.token;
                if (!token) {
                    this.showError(this.$t('MaintenanceDeviceData.not_logged_in'));
                    return;
                }

                const response = await axios.get(
                    `${backendUrl}/api/maintenance/deviceswithownerdata`,
                    {
                        params: {},
                        headers: { Authorization: `Bearer ${token}` }
                    });

                // check is request returned any error
                if (response.status !== 200) {
                    this.showError(response.data.error);
                    return;
                }

                // validate response data
                if (!response.data || !response.data.data) {
                    this.showError(this.$t('MaintenanceDeviceData.response_invalid_structure'));
                    return;
                }
                if (!response.data.success) {
                    this.showError(this.$t('MaintenanceDeviceData.response_with_error'));
                    return;
                }

                this.listOfDevices = response.data.data;
                return;

            } catch (error) {
                console.error(`fetchDevicesWithOwnersData: ${error}`);
                this.showError(this.$t('MaintenanceDeviceData.response_internal_error'));
                return;
            }
        },
        formatDateTime(utcDateTime) {
            if (utcDateTime == null)
                return "/";

            const date = new Date(utcDateTime);
            return date.toLocaleTimeString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false, // Ensures 24-hour format
            });
        },
    }
}
</script>

<style scoped>
.table-wrapper {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  background-color: white;
  margin-top: 16px;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin: 20px auto;
}

th {
  background-color: #cbccce;
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #c3c3c3;
  
  /* Typography */
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
tr {
  border-spacing: 0 8px;
}
td {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: none;
  padding: 14px 12px;
  font-size: 14px;
  color: #333;
  text-align: left;
  transition: background-color 0.2s ease;
  font-weight: 400;
}
tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
th:first-child {
  border-top-left-radius: 8px;
}
th:last-child {
  border-top-right-radius: 8px;
}
th.sorted {
  background-color: #e6eefc;
  color: #0f85c9;
}
tbody tr:nth-child(odd) td {
  background-color: #ffffff; /* white for odd rows */
}

tbody tr:nth-child(even) td {
  background-color: #dfe1e6; /* light grey/blue for even rows */
}
.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover td {
    background-color: #e9f2ff !important;
}

.status-column {
  text-align: center;
  width: 10%;
}

.serial-number-column {
    width: 15cap;
    text-align: left;
    /* Serial number column takes 20% */
}

.mac-address-column {
    width: 15%;
    text-align: left;
    /* MAC address column takes 25% */
}

.firmware-version-column {
    width: 10%;
    text-align: left;
    /* Firmware version column takes 15% */
}

.first-reg-date-column {
    width: 15%;
    text-align: left;
    /* Name column takes 15% */
}

.owner-data-column {
    width: 20%;
    text-align: left;
    /* Owner data column takes 20% */
}
.latest-data-column{
    width: 20%;
    text-align: left;

}


/* Status column styling */
.status-column {
    width: 5%;
    /* Adjusted to a smaller percentage width */
    text-align: center;
}

/* Responsive table */
@media (max-width: 768px) {

    th,
    td {
        font-size: 12px;
        /* Reduce the font size on smaller screens */
        padding: 8px;
        /* Less padding for smaller screens */
    }
}
.device-form-section {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
}

/* Flex container for input, refresh, and pagination */
.form-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

/* Input and refresh button aligned next to each other */
.input-refresh-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow this container to take full width of the container */
}

.modern-input {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.refresh-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
}

.refresh-btn i {
    pointer-events: none;
}

.refresh-btn:hover i {
    color: #007bff;
}

/* Pagination styling */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between the pagination buttons */
}

.pagination-button {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.pagination-button.active,
.pagination-button:hover:not(:disabled) {
  background-color: #007bff;
  color: white;
}

/* Adjustments for small screens */
@media (max-width: 768px) {
    .pagination-button {
        padding: 8px 10px;
        font-size: 14px;
    }

    .modern-input {
        font-size: 14px;
        padding: 8px;
    }
}
</style>
