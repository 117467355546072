import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { getLanguageCode, messages } from './language';
import App from './views/App.vue';
import router from './router';
import { initializeKeycloak } from './keycloak';  // Adjust the path if needed
import '@fortawesome/fontawesome-free/css/all.css';
import 'flag-icons/css/flag-icons.min.css';

const i18n = createI18n({ // Define your i18n setup
  locale: getLanguageCode,     // Set the default locale
  fallbackLocale: 'en',        // Fallback if translation is missing
  messages
});


initializeKeycloak().then(() => {
  const app = createApp(App);
  app.use(router);
  app.use(i18n);
  app.mount('#app');
}).catch(error => {
  console.error(`Keycloak initialization failed: ${error}`);
  alert(this.$t('main.failed_to_init'));
});
