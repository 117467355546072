// language.js

import en from './i18n/en.json';
import si from './i18n/si.json';

// Language flag config and metadata
const flags = {
  en: { name: 'English', flagCode: 'gb', language_code: 'en' },
  si: { name: 'Slovenian', flagCode: 'si', language_code: 'si' }
};

// Locale translation messages
const messages = {
  en,
  si
};

// Set the user's preferred language in localStorage
function setLanguage(langCode) {
  if (flags[langCode]) {
    localStorage.setItem('lang', langCode);
    console.log(`language change: ${langCode}`);
    return flags[langCode];
  }
  
  console.log(`language change: en`);
  return flags['en']; // fallback
}

// Get saved language code from localStorage, validate, or fallback to 'en'
function getLanguageCode() {
  const stored = localStorage.getItem('lang');
  var lang = flags[stored] ? stored : 'en';
  console.log(`language get: ${lang}`);
  return lang;
}

// Get full language object (for UI dropdowns, etc.)
function getLanguage() {
  return flags[getLanguageCode()];
}

export {
  flags,
  messages,
  setLanguage,
  getLanguageCode,
  getLanguage,
};
