<template>
  <div v-if="Object.keys(listSettings).length > 0" class="settings-section">
    <!-- listSettings Table -->
    <table  class="settings-table">
      <thead>
        <tr>
          <th>{{ $t('MaintenanceDeviceSettingsData.settings_name') }}</th>
          <th>{{ $t('MaintenanceDeviceSettingsData.current_latest_value') }}</th>
          <th>{{ $t('MaintenanceDeviceSettingsData.new_value') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.weight_oveload_limit') }}</td>
          <td>{{ listSettings['weight_oveload_limit'].current_value }}</td>
          <td><input
              type="number"
              v-model.number="newSettingsData['weight_oveload_limit']"
              :min="listSettings['weight_oveload_limit'].min_value"
              :max="listSettings['weight_oveload_limit'].max_value"
              :step="listSettings['weight_oveload_limit'].step_value"
              @blur="validateInput('weight_oveload_limit', 0)"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.weight_overpush_up') }}</td>
          <td>{{ listSettings['weight_overpush_up'].current_value }}</td>
          <td>
            <input
              type="number"
              v-model.number="newSettingsData['weight_overpush_up']"
              :min="listSettings['weight_overpush_up'].min_value"
              :max="listSettings['weight_overpush_up'].max_value"
              :step="listSettings['weight_overpush_up'].step_value"
              @blur="validateInput('weight_overpush_up', 0)"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.weight_kick_sensitivity') }}</td>
          <td>{{ listSettings['weight_kick_sensitivity'].current_value }}</td>
          <td>
            <input
              type="number"
              v-model.number="newSettingsData['weight_kick_sensitivity']"
              :min="listSettings['weight_kick_sensitivity'].min_value"
              :max="listSettings['weight_kick_sensitivity'].max_value"
              :step="listSettings['weight_kick_sensitivity'].step_value"
              @blur="validateInput('weight_kick_sensitivity', 0)"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.motor_current_limit') }}</td>
          <td>{{ listSettings['motor_current_limit'].current_value }}</td>
          <td>
            <input
              type="number"
              v-model.number="newSettingsData['motor_current_limit']"
              :min="listSettings['motor_current_limit'].min_value"
              :max="listSettings['motor_current_limit'].max_value"
              :step="listSettings['motor_current_limit'].step_value"
              @blur="validateInput('motor_current_limit', 1)"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.motor_temp_limit') }}</td>
          <td>{{ listSettings['motor_temp_limit'].current_value }}</td>
          <td>
            <input
              type="number"
              v-model.number="newSettingsData['motor_temp_limit']"
              :min="listSettings['motor_temp_limit'].min_value"
              :max="listSettings['motor_temp_limit'].max_value"
              :step="listSettings['motor_temp_limit'].step_value"
              @blur="validateInput('motor_temp_limit',0)"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.weight_operation_mode') }}</td>
          <td>{{ listSettings['weight_operation_mode'].current_value }}</td>
          <td>
            <label>
              <input
                type="radio"
                v-model="newSettingsData['weight_operation_mode']"
                :value="1"
              />
              {{ $t('MaintenanceDeviceSettingsData.mode_normal') }}
            </label>
            <label>
              <input
                type="radio"
                v-model="newSettingsData['weight_operation_mode']"
                :value="0"
              />
              {{ $t('MaintenanceDeviceSettingsData.mode_manual') }}
            </label>
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.serial_number') }}</td>
          <td>{{ listSettings['serial_number'].current_value }}</td>
          <td>
            <input
              type="text"
              v-model="newSettingsData['serial_number']"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.owner_desc') }}</td>
          <td>{{ listSettings['owner_desc'].current_value }}</td>
          <td>
            <input
              type="text"
              v-model="newSettingsData['owner_desc']"
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t('MaintenanceDeviceSettingsData.error_stack') }}</td>
          <td>{{ listSettings['error_stack'].current_value }}</td>
          <td>
            <button @click="clearErrorStack" class="back-button">{{ $t('MaintenanceDeviceSettingsData.clear') }}</button>
          </td>
        </tr>
        
        <tr>
          <td>Zero command</td>
          <td></td>
          <td>
            <button @click="sendZeroCommand" class="back-button">{{ $t('MaintenanceDeviceSettingsData.reset_zero') }}</button>
          </td>
        </tr>

        <tr>
          <td>Recalibrate</td>
          <td></td>
          <td>
            <button @click="sendRecalibrateCommand" class="back-button">{{ $t('MaintenanceDeviceSettingsData.recalibrate') }}</button>
          </td>
        </tr>
      </tbody>

    </table>
    <!-- Submit Button to update the settings, only visible when table has data -->
    <div class="button-container">
      <button @click="updateSettings" class="back-button">{{ $t('MaintenanceDeviceSettingsData.update') }}</button>
    </div>

  </div>
  <!-- Display message if no settings are found -->
  <p v-else>{{ $t('MaintenanceDeviceSettingsData.no_settings_found') }}</p>

</template>

<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    },
    macAddress: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      intervalId: null,
      listSettings: {}, // Store list of settings fetched from API
      newSettingsData: {}
    };
  },
  async mounted() {
    await this.fetchSettingsForDevice();
    this.CopyValuesFromSettings();

    this.scheduleNextFetch();
  },
  beforeUnmount() {
    clearTimeout(this.intervalId);
  },
  methods: {
    CopyValuesFromSettings(){  
      this.newSettingsData = {};
      
      this.newSettingsData['weight_oveload_limit'] = this.listSettings['weight_oveload_limit'].current_value;
      this.validateInput('weight_oveload_limit',0);
      
      this.newSettingsData['weight_overpush_up'] = this.listSettings['weight_overpush_up'].current_value;
      this.validateInput('weight_overpush_up',0);
      
      this.newSettingsData['weight_kick_sensitivity'] = this.listSettings['weight_kick_sensitivity'].current_value;
      this.validateInput('weight_kick_sensitivity',0);

      this.newSettingsData['motor_current_limit'] = this.listSettings['motor_current_limit'].current_value;
      this.validateInput('motor_current_limit',1);

      this.newSettingsData['motor_temp_limit'] = this.listSettings['motor_temp_limit'].current_value;
      this.validateInput('motor_temp_limit',0);

      this.newSettingsData['weight_operation_mode'] = this.listSettings['weight_operation_mode'].current_value === 0 ? 0 : 1;
      this.newSettingsData['error_stack'] = this.listSettings['error_stack'].current_value;
      this.newSettingsData['serial_number'] = this.listSettings['serial_number'].current_value;
      this.newSettingsData['owner_desc'] = this.listSettings['owner_desc'].current_value;
    },
    /// Emit events that trigger top level functions
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    validateInput(key, decNum) {
      let setting = this.listSettings[key];
      let value = parseFloat(this.newSettingsData[key]); // Ensure it's a number

      if (isNaN(value)) {
        // Default to min_value if the input is invalid
        this.newSettingsData[key] = setting.min_value;
      } else if (value < setting.min_value) {
        this.newSettingsData[key] = setting.min_value;
      } else if (value > setting.max_value) {
        this.newSettingsData[key] = setting.max_value;
      } else {
        // Round to either 0 or 1 decimal places
        this.newSettingsData[key] = parseFloat(value.toFixed(decNum));
      }
    },
    clearErrorStack(){
      this.newSettingsData['error_stack'] = 0;

    },
    async fetchSettingsForDevice() {
        if (this.isFetching) return; // ✅ Prevent overlapping requests
        this.isFetching = true;

        try {
          
            try {
                await keycloak.updateToken(30);
            } catch (tokenError) {
                console.error("Token update failed:", tokenError);
                this.showError(this.$t('MaintenanceDeviceSettingsData.not_logged_in'));
                this.isFetching = false;
                return;
            }

            const token = keycloak.token;
            if (!token) {
                this.showError(this.$t('MaintenanceDeviceSettingsData.not_logged_in'));
                this.isFetching = false;
                return;
            }

            const response = await axios.get(`${backendUrl}/api/maintenance/settings`, {
                params: { id: this.deviceId },
                headers: { Authorization: `Bearer ${token}` }
            });

            //validate response
            if (response.status !== 200 || !response.data || !response.data.success || !response.data.data) {
              console.error(response.data?.error);
              this.showError(this.$t('MaintenanceDeviceSettingsData.response_with_error'));
              return;
            }

            //store response
            this.listSettings = response.data.data || {};

        } catch (error) {
            console.error("fetchSettingsForDevice:", error);
            this.showError(this.$t('MaintenanceDeviceSettingsData.response_internal_error'));
        } finally {
            this.isFetching = false;
            this.scheduleNextFetch();
        }
    },
    formatLocalDate(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleDateString();
    },
    formatLocalTime(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleTimeString();
    },
    scheduleNextFetch() {
        this.intervalId = setTimeout(this.fetchSettingsForDevice, 5000);
    },
    async updateSettings() {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token; // Get Keycloak token for authorization

        if (!token) {
          console.error(`Authorization token is missing. Please log in.`);
          return;
        }

        var postData = {};
      
        postData['weight_oveload_limit'] = this.newSettingsData['weight_oveload_limit'];
        postData['weight_overpush_up'] = this.newSettingsData['weight_overpush_up'];
        postData['weight_kick_sensitivity'] = this.newSettingsData['weight_kick_sensitivity'];
        postData['motor_current_limit'] = Math.round(this.newSettingsData['motor_current_limit'] * 10);
        postData['motor_temp_limit'] = this.newSettingsData['motor_temp_limit'];
        postData['weight_operation_mode'] = this.newSettingsData['weight_operation_mode'];
        postData['error_stack'] = this.newSettingsData['error_stack'];
        postData['serial_number'] = this.newSettingsData['serial_number'];
        postData['owner_desc'] = this.newSettingsData['owner_desc'];

        const response = await axios.post(
          `${backendUrl}/api/maintenance/settings/update`,
          {
            settings_data: postData,
            id: this.macAddress
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        // Check if response status is successful
        if (response.status !== 200) {
          console.log(`Failed to update device`);
          return;
        }

        // Return the fetched logs data
        return response.data;

      } catch (error) {
        // Improve error handling to capture specific issues
        console.error(`Error updating settings for device ${this.macAddress}:`, error.message);
        return;
      }
    },
    async sendZeroCommand() {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token; // Get Keycloak token for authorization

        if (!token) {
          console.error(`Authorization token is missing. Please log in.`);
          return;
        }

        const response = await axios.post(
          `${backendUrl}/api/maintenance/zero`,
          { id: this.macAddress },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        // Check if response status is successful
        if (response.status !== 200) {
          console.log(`Failed to update device`);
          return;
        }

        // Return the fetched logs data
        return response.data;

      } catch (error) {
        // Improve error handling to capture specific issues
        console.error(`Error updating settings for device ${this.macAddress}:`, error.message);
        return;
      }
    },
    async sendRecalibrateCommand() {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token; // Get Keycloak token for authorization

        if (!token) {
          console.error(`Authorization token is missing. Please log in.`);
          return;
        }

        const response = await axios.post(
          `${backendUrl}/api/maintenance/recalibrate`,
          { id: this.macAddress },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        // Check if response status is successful
        if (response.status !== 200) {
          console.log(`Failed to update device`);
          return;
        }

        // Return the fetched logs data
        return response.data;

      } catch (error) {
        // Improve error handling to capture specific issues
        console.error(`Error updating settings for device ${this.macAddress}:`, error.message);
        return;
      }
    },
  }
};
</script>

<style scoped>
.settings-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.settings-table th,
.settings-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.settings-table th {
  background-color: #179b0b;
  color: white;
}

.settings-table tr:hover {
  background-color: #f1f1f1;
}

.settings-table td {
  color: #333;
}

.settings-section p {
  color: #7f8c8d;
  font-style: italic;
}

/* Container to hold the button in the bottom-right corner */
.button-container {
  display: flex;
  justify-content: flex-end; /* Push the button to the right */
  padding: 20px 0; /* Add space around the button */
}

/* Existing button styling */
.back-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #179b0b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Spacing for the icon */
.back-button i {
  margin-right: 8px;
}

/* Hover effect */
.back-button:hover {
  background-color: #1f7917;
}

/* Focus effect */
.back-button:focus {
  outline: none;
}

/* Active effect */
.back-button:active {
  background-color: #1e8015;
}
</style>